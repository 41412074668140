import React from 'react';

const ItStaffComponent = props => {
  const { title, description, arr1, arr2 } = props;

  return (
    <div className='it-staff'>
      <p
        className='it-staff__title'
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className='it-staff__description'
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className='full-container'>
        <div className='it-staff__wrapper'>
          <div className='it-staff__wrapper-col1'>
            {arr1.map((item, i) => (
              <div className={`it-staff__wrapper-content`} key={i}>
                <div className='it-staff__wrapper-content-icon'>
                  <p>{item.number}</p>
                </div>
                <div className='it-staff__wrapper-content-text'>
                  {item.title}
                  <p
                    className='it-staff__wrapper-content-text-description'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className='it-staff__wrapper-col2'>
            {arr2.map((item, i) => (
              <div
                className={`it-staff__wrapper-content ${
                  item.number == 4 && 'child-4'
                }`}
                key={i}
              >
                <div className='it-staff__wrapper-content-icon'>
                  <p>{item.number}</p>
                </div>
                <div className='it-staff__wrapper-content-text'>
                  {item.title}
                  <p
                    className='it-staff__wrapper-content-text-description'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItStaffComponent;
